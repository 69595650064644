import React from 'react'
import Foto from './Foto'

function Tripla({}) {
  return (
    <article id="appartamenti" className="container box style2">
      <header>
        <h2>La Camera Tripla</h2>
        <p>
        Un monolocale da 3 posti letto composto da zona giorno con angolo cottura, bagno con doccia e uno spazio riservato esterno
        </p>
      </header>
      <div className="inner gallery">
        <div className="row 0%">
          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla doppia singoli.jpg"
            thumb_path="/images/tripla/thumbs/gialla doppia singoli.jpg"
          />

          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla tripla 2.jpg"
            thumb_path="/images/tripla/thumbs/gialla tripla 2.jpg"
          />

          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla tripla terzo letto 2.jpg"
            thumb_path="/images/tripla/thumbs/gialla tripla terzo letto 2.jpg"
          />
          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla overview.jpg"
            thumb_path="/images/tripla/thumbs/gialla overview.jpg"
          />
        </div>
        <div className="row 0%">
          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla cucina2.jpg"
            thumb_path="/images/tripla/thumbs/gialla cucina2.jpg"
          />
          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla cucina3.jpg"
            thumb_path="/images/tripla/thumbs/gialla cucina3.jpg"
          />

          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla specchio bagno.jpg"
            thumb_path="/images/tripla/thumbs/gialla specchio bagno.jpg"
          />
          <Foto
            title="camera gialla"
            image_path="/images/tripla/gialla bagno 2.jpg"
            thumb_path="/images/tripla/thumbs/gialla bagno 2.jpg"
          />
        </div>
      </div>
    </article>
  )
}
export default Tripla
