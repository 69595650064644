import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Doppie from '../components/Doppie'
import Tripla from '../components/Tripla'
import Deluxe from '../components/Deluxe'
import MasseriaGallery from '../components/MasseriaGallery'
import { graphql } from 'gatsby'


class AppIndex extends React.Component {
  render() {
    const pageLinks = []
    const site = get(this, 'props.data.site.siteMetadata')

    return (
      <Layout>
        <div>
        <Helmet
          title={get(site, 'title')}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'twitter')}` },
            { property: 'og:title', content: get(site, 'title') },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: get(site, 'description') },
            { property: 'og:url', content: get(site, 'url') },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/profile.jpg`,
            },
          ]}
        />
        {/* <!-- Header --> */}
        <section id="header">
          <header>
            <h1>{get(site, 'title')}</h1>
            <p>{get(site, 'subtitle')}</p>
          </header>
          <footer>
            <a href="#banner" className="button style2 scrolly-middle">
              Scopri
            </a>
          </footer>
        </section>

        {/* <!-- Banner --> */}
        <section id="banner">
          <header>
            <h2>La Masseria e le Aree Comuni</h2>
          </header>
          <p>
          Dormirai in una tipica masseria del XIX secolo.
          Ci piace chiamarla "Urban Masseria", perché ci troviamo a pochi minuti dal centro di
          Ragusa, ma senza rinunciare agli spazi di una tipica masseria dell’800.
          La proprietà si trova nella splendida "Val di Noto" (Patrimonio Unesco).
          In soli 20/30 min. in auto potrete facilmente raggiungere Ibla, Marina di Ragusa, la
          Riserva Naturale di Randello, Modica, Scicli, Donnafugata, Punta Secca, e molte
          spiagge selvagge e sabbiose, guidando attraverso meravigliosi paesaggi
          mediterranei.
          </p>

          <section>
          <MasseriaGallery />
          </section>


          <footer>
            <a href="#appartamenti" className="button style2 scrolly-middle">
              Gli appartamenti
            </a>
          </footer>
        </section>

        {/* <!-- Appartamenti --> */}
        <Doppie />

        {/* <!-- A Casa Abitari --> */}
        <Tripla />

        {/* <!-- Volta --> */}
        <Deluxe />

        {/* <!-- Dammusi --> */}
        {/* <Dammusi /> */}
        {/*****************************
        <article className="container box style1 right">
          <a href="#" className="image fit">
            <img src="overflow/images/breakfast.jpg" alt="" />
          </a>
          <div className="inner">
            <header>
              <h2>
                La colazione &egrave;<br />
                Un ottimo inizio
              </h2>
            </header>
            <p>Da Mastro Vanni la colazione &egrave; un pasto importante!</p>
          </div>
        </article>
        *********************************/}

        {/*****************************
        <article className="container box style1 left">
          <a href="#" className="image fit">
            <img src="overflow/images/piscina.jpg" alt="" />
          </a>
          <div className="inner">
            <header>
              <h2>
                Fai una pausa<br />
                Te lo meriti!
              </h2>
            </header>
            <p>
              Una bellissima piscina a tua disposizione per nuotare o
              semplicemente rilassarti in tranquillit&agrave;!
            </p>
          </div>
        </article>
        ***************/}

        {/* <!-- Contact --> */}

        <article id="contact" className="container box style1 right">
          <a href="#" className="image fit">
            <img src="overflow/images/contact_us_page.jpg" alt="contact us" />
          </a>
          <div className="inner">
            <header>
              <h2>Contattaci</h2>
            </header>
            <p>
              Via email:{' '}
              <a href={'mailto:' + get(site, 'mail')}>{get(site, 'mail')}</a>
            </p>
            <p>
              Mobile: <strong>+ 39 329 5961173</strong>
            </p>
            {/* <p>
              Tel/Fax: <strong>+39 0932 667041</strong>
            </p> */}
          </div>
        </article>

        <section id="footer">
          <ul className="icons">
            <li>
              <a href={get(site, 'twitter')} className="icon fa-twitter">
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href={get(site, 'facebook')} className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href={get(site, 'instagram')} className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <div className="copyright">
            <ul className="menu">
              <li>&copy; MastroVanni All rights reserved.</li>
            </ul>
          </div>
        </section>
      </div>
      </Layout>
    )
  }
}

export default AppIndex

export const pageQuery = graphql`
  query AppIndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        description
        url: siteUrl
        author
        twitter
        adsense
        instagram
        facebook
        mail
      }
    }
  }
`
