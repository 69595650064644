import React from 'react'
import Foto from './Foto'

function Deluxe({}) {
  return (
    <article id="appartamenti" className="container box style2">
      <header>
        <h2>La Camera Deluxe</h2>
        <p>
          Una suggestiva e romantica alcova da 2/4 posti letto composta da un
          ambiente molto ampio, una cucina ed un bagno con doccia.
        </p>
      </header>
      <div className="inner gallery">
        <div className="row 0%">
        <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta cuscini e rosa.jpg"
            thumb_path="/images/deluxe/thumbs/volta cuscini e rosa.jpg"
          />
          <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta letto.jpg"
            thumb_path="/images/deluxe/thumbs/volta letto.jpg"
          />
          <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta baldacchino.jpg"
            thumb_path="/images/deluxe/thumbs/volta baldacchino.jpg"
          />

          <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta particolare nicchia.jpg"
            thumb_path="/images/deluxe/thumbs/volta particolare nicchia.jpg"
          />



        </div>
        <div className="row 0%">
        <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta bagno.jpg"
            thumb_path="/images/deluxe/thumbs/volta bagno.jpg"
          />

          <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta specchio bagno.jpg"
            thumb_path="/images/deluxe/thumbs/volta specchio bagno.jpg"
          />
          <Foto
            title="camera deluxe"
            image_path="/images/deluxe/volta esterno.jpg"
            thumb_path="/images/deluxe/thumbs/volta esterno.jpg"
          />


          <Foto
            title="camera deluxe"
            image_path="/images/deluxe/deluxe-1.jpg"
            thumb_path="/images/deluxe/thumbs/deluxe-1.jpg"
          />


        </div>
      </div>
    </article>
  )
}
export default Deluxe
