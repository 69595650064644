import React from 'react'
import Foto from './Foto'

export default function MasseriaGallery({}) {
  return (
    <article className="container box style2">
      <div className="inner gallery">
        <div className="row 0%">
          <Foto
            title="camera deluxe"
            image_path="/images/masseria/ingresso.jpg"
            thumb_path="/images/masseria/thumbs/ingresso.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/ingresso2.jpg"
            thumb_path="/images/masseria/thumbs/ingresso2.jpg"
          />
          <Foto
            title="camera deluxe"
            image_path="/images/masseria/arco.jpg"
            thumb_path="/images/masseria/thumbs/arco.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/reception.jpg"
            thumb_path="/images/masseria/thumbs/reception.jpg"
          />
        </div>
        <div className="row 0%">
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/reception 2.jpg"
            thumb_path="/images/masseria/thumbs/reception 2.jpg"
          />

          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/reception work.jpg"
            thumb_path="/images/masseria/thumbs/reception work.jpg"
          />

          <Foto
            title="camera deluxe"
            image_path="/images/masseria/corridoio stalle.jpg"
            thumb_path="/images/masseria/thumbs/corridoio stalle.jpg"
          />

          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/retro.jpg"
            thumb_path="/images/masseria/thumbs/retro.jpg"
          />
        </div>
        <div className="row 0%">
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/reception e arco serale.jpg"
            thumb_path="/images/masseria/thumbs/reception e arco serale.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/reception serale.jpg"
            thumb_path="/images/masseria/thumbs/reception serale.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/porta baglio serale.jpg"
            thumb_path="/images/masseria/thumbs/porta baglio serale.jpg"
          />

          <Foto
            title="camera deluxe"
            image_path="/images/masseria/porta baglio 2.jpg"
            thumb_path="/images/masseria/thumbs/porta baglio 2.jpg"
          />
        </div>
        <div className="row 0%">
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-1.jpg"
            thumb_path="/images/masseria/thumbs/masseria-1.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-2.jpg"
            thumb_path="/images/masseria/thumbs/masseria-2.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-3.jpg"
            thumb_path="/images/masseria/thumbs/masseria-3.jpg"
          />

          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-4.jpg"
            thumb_path="/images/masseria/thumbs/masseria-4.jpg"
          />
        </div>
        <div className="row 0%">
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-5.jpg"
            thumb_path="/images/masseria/thumbs/masseria-5.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-6.jpg"
            thumb_path="/images/masseria/thumbs/masseria-6.jpg"
          />
          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-7.jpg"
            thumb_path="/images/masseria/thumbs/masseria-7.jpg"
          />

          <Foto
            title="mastrovanni - urban masseria"
            image_path="/images/masseria/masseria-8.jpg"
            thumb_path="/images/masseria/thumbs/masseria-8.jpg"
          />
        </div>
      </div>
    </article>
  )
}
