import React from 'react'
export default function Foto({
  title,
  image_path,
  thumb_path
}) {
  return (
    <div className="3u 12u(mobile)">
      <a href={image_path} className="image fit">
        <img
          src={thumb_path}
          alt=""
          title={title}
        />
      </a>
    </div>
  );
}
