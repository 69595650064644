import React from 'react'
import Foto from './Foto'

function Doppie({}) {
  return (
    <article id="appartamenti" className="container box style2">
      <header>
        <h2>Le Camere Doppie</h2>
        <p>
          Due monolocali da 2 posti letto con cucina completamente attrezzata,
          bagno con doccia e una verandina indipendente.
        </p>
      </header>
      <div className="inner gallery">
        <div className="row 0%">
          <Foto
            title="camera rossa"
            image_path="/images/doppie/rossa letto.jpg"
            thumb_path="/images/doppie/thumbs/rossa letto.jpg"
          />
          <Foto
            title="camera rossa"
            image_path="/images/doppie/rossa letto e towels.jpg"
            thumb_path="/images/doppie/thumbs/rossa letto e towels.jpg"
          />
          <Foto
            title="camera rossa"
            image_path="/images/doppie/rossa overview.jpg"
            thumb_path="/images/doppie/thumbs/rossa overview.jpg"
          />

          <Foto
            title="camera rossa"
            image_path="/images/doppie/rossa bagno.jpg"
            thumb_path="/images/doppie/thumbs/rossa bagno.jpg"
          />
        </div>
        <div className="row 0%">
          <Foto
            title="camera verde"
            image_path="/images/doppie/verde letti singoli.jpg"
            thumb_path="/images/doppie/thumbs/verde letti singoli.jpg"
          />
          <Foto
            title="camera verde"
            image_path="/images/doppie/verde_IMG_2898.jpg"
            thumb_path="/images/doppie/thumbs/verde_IMG_2898.jpg"
          />
          <Foto
            title="camera verde"
            image_path="/images/doppie/verde_IMG_2899.jpg"
            thumb_path="/images/doppie/thumbs/verde_IMG_2899.jpg"
          />

          <Foto
            title="camera verde"
            image_path="/images/doppie/verde bagno 2.jpg"
            thumb_path="/images/doppie/thumbs/verde bagno 2.jpg"
          />
        </div>
      </div>
    </article>
  )
}
export default Doppie
